import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { ProcessFilter } from './process-filter.types';

@Injectable({
    providedIn: 'root',
})
export class ProcessFilterService {
    private _filterValues: WritableSignal<ProcessFilter> = signal({});
    public get filterValues(): Signal<ProcessFilter> {
        return this._filterValues.asReadonly();
    }
    public set filterValues(value: ProcessFilter) {
        this._filterValues.set(value);
    }

    public updateFilterValues(newValue: ProcessFilter) {
        this._filterValues.update(value => {
            return { ...value, ...newValue };
        });
    }
}

import { Inject, Injectable, WritableSignal, effect, signal } from '@angular/core';
import { TypeOfProcess } from '@em4cloud/process-tree';
import {
    CanvasData,
    FileManagerService,
    FileUrlList,
    OrganisationStructure,
    OrganisationStructureControllerService,
    Process,
    ProcessMigrate,
} from 'app/api';
import { BusinessProcessService } from 'app/api/api/businessProcess.service';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { FrontendAppConfiguration } from 'app/services/configuration.services';
import { LanguageChangerService } from 'app/services/translate_api/languageChanger.service';
import { Observable, Subject, of, tap } from 'rxjs';
import { ProcessFilterService } from './processes-filter/process-filter.service';

@Injectable({
    providedIn: 'root',
})
export class ProcessService {
    _destroy: Subject<void> = new Subject();

    // master state for processes fetched from API
    public _state = signal<Process[]>([]);

    // currently selected process
    private _selectedProcess = signal<Process>(null);

    // previous selected process id
    private _previousSelectedProcessId: string;

    // to maintain state for undo/redo functionality
    _history: Process[][] = [];
    _future: Process[][] = [];

    // if the selected process is in edit mode currently
    private _editableForm = signal<boolean>(false);

    // variable to store edited processes
    editedProcessesSignal: WritableSignal<Process[]> = signal([]);

    // current page view
    _view = signal<TypeOfProcess>(null);

    // image files
    currentImgUrls: FileUrlList = {
        urls: [],
    };

    imgUrlsToDelete: FileUrlList = {
        urls: [],
    };

    isValidationOngoing: boolean = false;

    _organisation: WritableSignal<OrganisationStructure[]> = signal(null);

    // string to replace as the userId while calling backend
    private replacableId: string;

    constructor(
        private apiService: BusinessProcessService,
        private fileManagerService: FileManagerService,
        private saveButtonService: SaveButtonService,
        private languageChangerService: LanguageChangerService,
        private _processFilterService: ProcessFilterService,
        private _areaFilterService: OrganisationFilterService,
        private organisationService: OrganisationStructureControllerService,
        @Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration
    ) {
        this.replacableId = this.appConfig.basicClientIdToBeReplaced;
        this.languageChangerService.register('ProcessService', this.initializeData.bind(this));

        effect(
            () => {
                if (this._selectedProcess()?.id !== this._previousSelectedProcessId) {
                    this._previousSelectedProcessId = this._selectedProcess()?.id;
                    this.saveButtonService.hasUnsavedChanges = false;
                }
            },
            {
                allowSignalWrites: true,
            }
        );
        effect(() => {
            if (this._areaFilterService.selectedOrganisation()) {
                this.initializeData();
            }
        });
    }

    get editableForm() {
        return this._editableForm();
    }

    get selectedProcess() {
        return this._selectedProcess;
    }

    get state() {
        return this._state();
    }

    get filteredState() {
        return this._state().filter(this.processFilterFn);
    }

    get view() {
        return this._view();
    }

    get organisation() {
        return this._organisation();
    }

    initializeData() {
        // return if the data is already initialized
        // if (this._state()?.length > 0) return;
        const subs = this.getBpls().subscribe(res => {
            if (this.selectedProcess()) {
                const id = this.selectedProcess().id;
                const process = this.state.find(process => process.id === id);

                this.setSelectedProcess(process, true);
                this.saveButtonService.hasUnsavedChanges = false;
            }
            subs.unsubscribe();
        });
    }

    processFilterFn = (process: Process) => {
        let filterValues = this._processFilterService.filterValues();
        if (!filterValues) return true;

        let searchStringPresent = filterValues.searchString
            ? process.wbs?.toLowerCase().indexOf(filterValues.searchString.toLowerCase()) >= 0
            : true;
        let statusPresent =
            filterValues.status?.length > 0 ? filterValues.status.includes(process.status) : true;
        let ownerPresent =
            filterValues.owner?.length > 0 ? filterValues.owner.includes(process.owner) : true;
        let authGroupPresent =
            filterValues.authGroup?.length > 0
                ? filterValues.authGroup.includes(process.auth)
                : true;
        let areaPresent =
            filterValues.areas?.length > 0 ? filterValues.areas.includes(process.area) : true;

        return (
            searchStringPresent && statusPresent && ownerPresent && authGroupPresent && areaPresent
        );
    };

    // -------------
    // API REQUESTS SECTION START
    // -------------

    /**
     * GET list of business processes
     *
     * @returns
     */
    getBpls(): Observable<Process[]> {
        if (!this._areaFilterService.selectedOrganisation()) {
            return of([]);
        }
        return this.apiService.getProcessById().pipe(
            tap((processes: Process[]) => {
                this.setState(processes);
            })
        );
    }

    async getBplsAsync(): Promise<void> {
        if (!this._areaFilterService.selectedOrganisation()) {
            this.setState([]);
            return;
        }
        const processes = await this.apiService.getProcessById().toPromise();

        this.setState(processes);
    }

    getOrganisationById(id: string) {
        this.organisationService
            .getOrganisationStructure(id)
            .subscribe(data => this._organisation.set(data));
    }

    /**
     * UPDATE list of business processes
     *
     * @param process
     * @returns
     */
    updateBpls(process: Process[]): Observable<Array<Process>> {
        return this.apiService.updateProcessesById(this.replacableId, process);
    }

    /**
     * COPY a business process
     *
     * @param bpId
     * @returns
     */
    copyBp(bpId: string): Observable<any> {
        return this.apiService.copyProcessById(bpId);
    }

    /**
     * DELETE a business process
     *
     * @param bpId
     * @returns
     */
    deleteBp(bpId: string): Observable<any> {
        return this.apiService.deleteProcessById(bpId).pipe((response: any) => response);
    }

    // -------------
    // DELETE UNUSED IMAGES
    // -------------

    async deleteImages(): Promise<any> {
        if (this.imgUrlsToDelete.urls.length) {
            await this.fileManagerService.deleteFiles(this.imgUrlsToDelete).toPromise();
            this.imgUrlsToDelete.urls = [];
        }
    }

    async getCanvas(bpId: string, taskId?: string): Promise<CanvasData> {
        if (taskId) {
            return await this.apiService.getCanvasForTask(taskId, bpId).toPromise();
        }
        return await this.apiService.getBusinessProcessCanvasById(bpId).toPromise();
    }

    async updateCanvas(bpId: string, canvasData: CanvasData, taskId?: string): Promise<void> {
        if (taskId) {
            await this.apiService.updateCanvasForTask(taskId, bpId, canvasData).toPromise();
            return;
        }
        await this.apiService.updateBusinessProcessCanvas(bpId, canvasData).toPromise();
    }

    // -------------
    // API REQUESTS SECTION END
    // -------------

    setEditableForm(status: boolean) {
        this._editableForm.set(status);
    }

    setSelectedProcess(process: Process, force?: boolean) {
        if (
            force ||
            !this._selectedProcess() ||
            (this._selectedProcess() && this._selectedProcess().id !== process.id)
        ) {
            this._selectedProcess.set(process);
            this.saveButtonService.hasUnsavedChanges = false;
        }
    }

    public setState(newState: Process[]): void {
        this._history.push(this._state());
        this._future = [];
        this._state.set(newState);
    }

    // restore from history
    public undo(): void {
        if (this._history.length > 0) {
            this._future.push(this._state());
            this._state.set(this._history.pop()!);
        }
    }

    // restore from future if exist
    public redo(): void {
        if (this._future.length > 0) {
            this._history.push(this._state());
            this._state.set(this._future.pop()!);
        }
    }

    // limit for history size
    public limitHistorySize(maxSize: number): void {
        while (this._history.length > maxSize) {
            this._history.shift();
        }
    }

    addNewBp(id: string, process: Partial<Process>): Observable<any> {
        return this.apiService.addNewProcess(process).pipe();
    }

    createMigrationBp(id: string, process: Partial<ProcessMigrate>): Observable<any> {
        return this.apiService.backendApiBpMigrateToProcessPost(id, process).pipe();
    }

    generateDescriptionByAi(id: string, process: Partial<Process>): Observable<any> {
        return this.apiService.generateDescriptionByAI(id, process).pipe();
    }

    updateFlowchartImageOfProcess(urlsInOrder: any): Observable<any> {
        return this.apiService.updateFlowchartImage(this.selectedProcess().id, urlsInOrder);
    }

    getFlowchartImageOfProcess(): Observable<any> {
        return this.apiService.getFlowchartImage(this.selectedProcess().id);
    }
}
